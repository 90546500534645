<template>
  <BaseModalContent
    name="PortfolioModal"
    :dynamic="true"
    @close="$emit('close')">
    <div>
      <h2 class="text-bold text-size-h2 mb-m">Публичное портфолио</h2>
      <div class="typography mb-l">
        <p>
          В&nbsp;публичном портфолио видны все ваши достижения
          в&nbsp;мероприятиях, в&nbsp;которых вы&nbsp;участвовали
          на&nbsp;платформе &laquo;Талант&raquo;.
        </p>
        <p>
          Публичное портфолио поможет вам рассказать о&nbsp;своем опыте другим
          участникам. Это может быть полезно для поиска команды на&nbsp;НТО!
        </p>
        <div v-if="isPublish">
          <p>
            Ваше портфолио уже опубликовано и доступно по ссылке
            <a
              class="link"
              target="_blank"
              rel="noopener noreferrer"
              :href="portfolioURL"
              >{{ portfolioURL }}</a
            >
          </p>

          <p>
            Вы можете управлять отображением портфолио на
            <a
              :href="profileUrl"
              class="link"
              target="_blank"
              >платформе «Талант»</a
            >.
          </p>
        </div>
      </div>

      <div v-if="isPublish">
        <BaseButton
          class="mr-s"
          @click="emit('close')"
          >Закрыть</BaseButton
        >
        <BaseButton
          type="button"
          theme="primary-border"
          tag="a"
          :href="profileUrl"
          target="_blank"
          @click="$emit('close')"
          >Перейти к настройкам</BaseButton
        >
      </div>
      <div v-else>
        <BaseButton
          class="mr-s"
          tag="a"
          :href="profileUrl"
          target="_blank"
          >Открыть портфолио</BaseButton
        >
        <BaseButton
          type="button"
          theme="primary-border"
          @click="$emit('close')"
          >Отмена</BaseButton
        >
      </div>
    </div>
  </BaseModalContent>
</template>

<script>
export default {
  name: "PortfolioModal",

  computed: {
    isPublish() {
      return this.user.public_page_enabled;
    },
    user() {
      return this.$store.getters["user/user"];
    },
    portfolioURL() {
      if (!this.user?.talent_id) return;
      return `${process.env.VUE_APP_TALENT_BASE_URL}/user/${this.user?.talent_id}`;
    },
    profileUrl() {
      return `${process.env.VUE_APP_TALENT_BASE_URL}/profile?open_portfolio=1`;
    },
  },
};
</script>

<style></style>
