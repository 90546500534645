<template>
  <div>
    <div v-if="pending"><BaseSpinner brand /></div>
    <div v-else>
      <div v-if="!profile">Нет такого профиля</div>
      <div v-else-if="!currentStage">Нет такого этапа по профилю</div>
      <div v-else>
        <IndexSlider
          :slides="slides"
          :pending="bannersPending" />
        <ProfilePanel
          :id="profileId"
          :stage="stageLabel"
          class="mb-l">
          <div
            v-if="stageDescription"
            class="row mb-m">
            <div class="col-md-9 col-xs-12">
              <div class="text-pre-wrap">{{ stageDescription }}</div>
            </div>
          </div>

          <div v-if="isUserTeamStage && teamChats.length">
            <div class="mt-m team-links-wrap">
              <div class="text-bold mb-xs">
                Полезные ссылки командного этапа:
              </div>
              <ul class="team-links">
                <li
                  v-for="chat in teamChats"
                  :key="chat.id"
                  class="team-links__item">
                  <a
                    :href="chat.url"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="link"
                    >{{ chat.title }}</a
                  >
                </li>
              </ul>
            </div>

            <!-- <div v-if="$store.state.tgBotVideo.length" class="mt-m">
              <div class="text-bold mb-xs">
                Скринкасты по командообразованию через Telegram бота:
              </div>
              <ul class="team-links">
                <li
                  v-for="(chat, idx) in $store.state.tgBotVideo"
                  :key="idx"
                  class="team-links__item"
                >
                  <a
                    :href="chat.url"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="link"
                    >{{ chat.title }}</a
                  >
                </li>
              </ul>
            </div> -->
          </div>
        </ProfilePanel>
        <KeepAlive>
          <Component
            :is="currentComponent"
            :profile-id="profileId"
            :activities="activities" />
        </KeepAlive>
      </div>
    </div>
  </div>
</template>

<script>
import ProfilePanel from "@/components/profile/ProfilePanel";
import IndividualProfileStage from "@/components/user/IndividualProfileStage.vue";
import TeamProfileStage from "@/components/user/TeamProfileStage.vue";
import FinalProfileStage from "@/components/user/FinalProfileStage.vue";
import FinalJuniorProfileStage from "@/components/user/FinalJuniorProfileStage.vue";
import { FINAL_STAGE, INDIVIDUAL_STAGE, TEAM_STAGE } from "@/constants";
import IndexSlider from "@/components/index-slider/IndexSlider.vue";

import { mapGetters } from "vuex";

export default {
  name: "UserProfile",
  components: {
    ProfilePanel,
    IndividualProfileStage,
    TeamProfileStage,
    FinalProfileStage,
    FinalJuniorProfileStage,
    IndexSlider,
  },
  metaInfo() {
    let title = "";
    if (this.profile) {
      title = this.profile.title;
    } else {
      title = "Страница профиля";
    }
    if (this.stageNumber) {
      title += `. Этап ${this.stageNumber}`;
    }
    return {
      title,
    };
  },
  props: {
    stageNumber: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      pending: true,
      bannersPending: false,
      banners: [],
    };
  },
  computed: {
    ...mapGetters({
      activityScores: "participant/activityScores",
      isStudent: "participant/isStudent",
      isJunior: "participant/isJunior",
      firstStage: "participant/firstStage",
      secondStage: "participant/secondStage",
      thirdStage: "participant/thirdStage",
      teamStageList: "participant/teamStageList",
    }),
    profileId() {
      return Number(this.$route.params.profile_id);
    },
    profile() {
      return this.$store.state.profile.profiles[this.profileId];
    },
    activities() {
      const { currentStage } = this;
      if (!currentStage) return [];
      return this.$store.getters["participant/activitiesByStage"](
        currentStage
      ).filter((activity) => {
        return activity.profiles.includes(this.profileId);
      });
    },
    stageLabel() {
      const { stageNumber, currentStage } = this;
      if (currentStage === FINAL_STAGE) {
        return "Финал";
      }
      return `Этап ${stageNumber}`;
    },
    currentStage() {
      const { stageNumber, firstStage, secondStage, thirdStage } = this;
      if (stageNumber === 1 && firstStage) {
        return firstStage.stage;
      }
      if (stageNumber === 2 && secondStage) {
        return secondStage?.stage;
      }
      if (stageNumber === 3 && thirdStage) {
        return thirdStage?.stage;
      }
      return undefined;
    },
    /**
     * Описание стейджа для профиля
     */
    stageDescription() {
      const { currentStage, profile, stageNumber, profileId } = this;
      // Описание 1 этапа нужно показывать всем
      // независимо от того, выбран профиль или нет
      if (stageNumber === 1) {
        switch (currentStage) {
          case TEAM_STAGE:
            return profile?.team_stage_description;
          case INDIVIDUAL_STAGE:
            return profile?.first_stage_description;
          default:
            return undefined;
        }
      }

      // описание 2 и 3 этапов нужно показывать
      // только если юзер в них прошел
      const userProfiles = this.$store.state.participant.profiles;
      const hasStage = userProfiles.some(
        (p) =>
          p.profile_id === profileId && !p.rejected && p.stage === currentStage
      );
      if (!hasStage) return;

      switch (currentStage) {
        case TEAM_STAGE:
          return profile?.team_stage_description;
        case INDIVIDUAL_STAGE:
          return profile?.first_stage_description;
        case FINAL_STAGE:
          return profile?.final_description;
        default:
          return undefined;
      }
    },
    currentComponent() {
      const { currentStage } = this;
      if (currentStage === TEAM_STAGE) {
        return "TeamProfileStage";
      } else if (currentStage === INDIVIDUAL_STAGE) {
        return "IndividualProfileStage";
      } else if (currentStage === FINAL_STAGE) {
        return "FinalProfileStage";
      }
      return "div";
    },
    isUserTeamStage() {
      const { currentStage, teamStageList } = this;
      return (
        currentStage === TEAM_STAGE &&
        teamStageList?.some((n) => n.profile_id === this.profileId)
      );
    },
    teamChats() {
      const { profile } = this;
      if (!profile?.chats?.length) {
        return [];
      }
      return profile.chats.filter((c) => c.teamlink || c.team_secret);
    },
    slides() {
      const { banners } = this;
      if (!banners?.length) {
        return [];
      }
      return banners.map((n) => {
        return {
          type: "banner",
          ...n,
        };
      });
    },
  },
  watch: {
    profileId: {
      handler(val) {
        this.$store.dispatch("profile/getProfileSchedule", val);
        this.getBanners();
      },
    },
  },
  async created() {
    this.pending = true;
    try {
      await this.$store.dispatch("profile/getProfiles");
      await this.$store.dispatch("participant/getScores");
      await this.$store.dispatch("profile/getProfileSchedule", this.profileId);
    } catch (error) {
      console.log("error", error);
    }
    this.pending = false;
    this.getBanners();
    this.$store.dispatch("participant/getEduBlocks");
  },
  methods: {
    /**
     * Получаем список баннеров
     * по всему треку
     */
    async getBanners() {
      this.bannersPending = true;
      try {
        const data = await this.$store.dispatch("getBanners", {
          profile_id: this.profileId,
        });
        this.banners = data.items;
      } catch (error) {
        // do nothing
      }
      this.bannersPending = false;
    },
  },
};
</script>

<style lang="less" scoped>
.team-links-wrap {
  padding: 16px;
  border-left: 4px solid @success-color;
  background: fade(@white-blue, 10%);
}
.team-links {
  list-style-type: none;
  &__item {
    display: inline-block;
    vertical-align: middle;
    margin-right: 1.4em;
    color: @link-color;
    .rem(margin-bottom, @margin-xs);
    &::before {
      content: "";
      display: inline-block;
      vertical-align: middle;
      width: 0.4em;
      height: 0.4em;
      margin-right: 0.4em;
      background-color: @link-color;
      transform: rotateZ(45deg);
      opacity: 0.3;
    }
  }
}
</style>
