<template>
  <div class="leave-team-modal">
    <BaseModalContent
      :dynamic="true"
      @close="$emit('close')">
      <h2 class="text-bold text-size-h2 mb-m">Покинуть команду</h2>
      <BaseLoadingBox :pending="pending">
        <div v-if="!team">Ошибка. Попробуйте перезагрузить страницу</div>
        <div v-else>
          <!-- Если юзер может покинуть команду -->
          <div v-if="leaveStatus === 'ok'">
            <p class="mb-m">Вы&nbsp;уверены что хотите покинуть команду?</p>

            <div>
              <BaseButton
                theme="primary"
                @click.prevent="handleLeaveTeam"
                >Да, покинуть</BaseButton
              >&emsp;
              <BaseButton
                theme="primary-border"
                @click.prevent="$emit('close')"
                >Нет, остаться</BaseButton
              >
            </div>
          </div>
          <!-- Если юзер может покинуть команду -->
          <div v-if="leaveStatus === 'has_members'">
            <p class="mb-m">
              Капитан покидает корабль последним! Чтобы покинуть команду,
              сначала передайте управление другому участнику, или исключите
              остальных участников, а&nbsp;затем покиньте команду
            </p>
            <div>
              <BaseButton
                theme="primary-border"
                @click.prevent="$emit('close')"
                >Понятно</BaseButton
              >
            </div>
          </div>
          <!-- Если юзер может покинуть команду -->
          <div v-if="leaveStatus === 'only_owner'">
            <p class="mb-m">
              Текущая команда будет удалена. Вы&nbsp;уверены что хотите покинуть
              команду?
            </p>
            <div>
              <div>
                <BaseButton
                  class="mb-m"
                  theme="primary"
                  @click.prevent="handleLeaveTeam"
                  >Да, удалить</BaseButton
                >&emsp;
                <BaseButton
                  theme="primary-border"
                  @click.prevent="$emit('close')"
                  >Нет, остаться</BaseButton
                >
              </div>
            </div>
          </div>
        </div>
      </BaseLoadingBox>
    </BaseModalContent>
  </div>
</template>

<script>
/**
 * Модалка - Покинуть команду
 */
import { talentRequest } from "@/services/api";

export default {
  name: "LeaveTeamModal",
  props: {
    profileId: {
      type: Number,
      required: true,
    },
    personId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      pending: false,
    };
  },
  computed: {
    /**
     * Статус - может ли юзер покинуть команду
     * @returns {string}
     */
    leaveStatus() {
      const { user, team } = this;
      if (!user || !team) return;
      if (user.talent_id === team.owner && team.persons.length > 1) {
        return "has_members";
      } else if (user.talent_id === team.owner) {
        return "only_owner";
      }
      return "ok";
    },
    team() {
      return this.$store.state.participant.teams[this.profileId];
    },
    user() {
      return this.$store.getters["user/user"];
    },
  },
  methods: {
    async handleLeaveTeam() {
      const { leaveStatus } = this;
      this.pending = true;
      try {
        let url = `/api/users/${this.user.talent_id}/teams/${this.team?.id}/user/${this.personId}/`;
        // если в команде только кеп, то удаляем команду
        // иначе удаляем юзера из команды
        if (leaveStatus === "only_owner") {
          url = `/api/users/${this.user.talent_id}/teams/${this.team?.id}/`;
        }
        await talentRequest({
          method: "DELETE",
          url,
        });
      } catch (error) {
        this.showErrorModal({
          title: "Не удалось покинуть команду",
          message: error.message,
        });
        this.pending = false;
        this.$emit("close");
        return;
      }
      const stage = this.$store.getters["participant/teamStageList"].find(
        (n) => {
          return n.profile_id === this.profileId;
        }
      );
      this.$store.commit("participant/DELETE_TEAM", this.profileId);
      this.$store.commit("participant/UPDATE_PROFILE_STAGE", {
        ...stage,
        team_id: null,
      });
      this.$emit("close");
      this.pending = false;
    },
  },
};
</script>

<style></style>
