<template>
  <section class="panel radius-l">
    <h2 class="text-size-h3 text-bold mb-xs">{{ event.title }}</h2>
    <div>
      <span class="text-bold">Формат мероприятия</span>: {{ event.format }}
    </div>
    <div v-if="dateInfo">
      <span class="text-bold">Дата проведения</span>: {{ dateInfo }}
    </div>
    <div v-if="event.format === 'offline' && event.address">
      <span class="text-bold">Место проведения</span>: {{ event.address }}
    </div>
    <div class="mt-s">{{ event.description }}</div>
  </section>
</template>

<script>
import dayjs from "@/plugins/dayjs";
import { DATE_FROMAT_WITH_UTC } from "@/constants";
export default {
  name: "FinalInfo",
  props: {
    event: {
      type: Object,
      required: true,
    },
  },
  computed: {
    dateInfo() {
      const { event } = this;
      if (!event.start) return;
      const start = dayjs(event.start).format(DATE_FROMAT_WITH_UTC);
      if (!event.end) {
        return start;
      }
      return `c ${start} по ${dayjs(event.end).format(DATE_FROMAT_WITH_UTC)}`;
    },
  },
};
</script>

<style></style>
