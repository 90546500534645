<template>
  <div class="leave-team-modal">
    <BaseModalContent
      :dynamic="true"
      @close="$emit('close')">
      <h2 class="text-bold text-size-h2 mb-m">Исключить из команды</h2>
      <BaseLoadingBox :pending="pending">
        <div v-if="!team">Ошибка. Попробуйте перезагрузить страницу</div>
        <div v-else>
          <p class="mb-m">
            Вы уверены что хотите исключить участника {{ personName }} из
            команды?
          </p>

          <div v-if="leaveStatus === 'ok'">
            <BaseButton
              theme="primary"
              @click.prevent="handleRemoveMember"
              >Да, уверен</BaseButton
            >&emsp;
            <BaseButton
              theme="primary-border"
              @click.prevent="$emit('close')"
              >Нет, я передумал</BaseButton
            >
          </div>
        </div>
      </BaseLoadingBox>
    </BaseModalContent>
  </div>
</template>

<script>
/**
 * Модалка для удаления друзяшек из команды :(
 */
import findIndex from "lodash/findIndex";
import { talentRequest } from "@/services/api";
export default {
  name: "RemoveTeamMemberModal",
  props: {
    profileId: {
      type: Number,
      required: true,
    },
    person: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      pending: false,
    };
  },
  computed: {
    /**
     * Статус - может ли юзер покинуть команду
     * @returns {string}
     */
    leaveStatus() {
      return "ok";
    },
    team() {
      return this.$store.state.participant.teams[this.profileId];
    },
    personName() {
      const { person } = this;
      return [person.first_name, person.last_name].filter(Boolean).join(" ");
    },
    user() {
      return this.$store.getters["user/user"];
    },
  },
  methods: {
    async handleRemoveMember() {
      this.pending = true;
      // Сначала удалим из онти, потому что здесь,
      // вероятность ошибки больше.
      // try {
      //   await request({
      //     method: "DELETE",
      //     url: `/team/${this.team?.id}`,
      //     data: {
      //       talent_id: this.person?.id,
      //     },
      //   });
      // } catch (error) {
      //   this.showErrorModal({
      //     title: "Не удалось удалить участника",
      //     message: error.message,
      //     content: "Пожалуйста, сообщите нам об этой ошибке",
      //   });
      //   this.pending = false;
      //   this.$emit("close");
      //   return;
      // }
      // Если все ок то удаляем из Таланта
      try {
        await talentRequest({
          method: "DELETE",
          url: `/api/users/${this.user.talent_id}/teams/${this.team?.id}/user/${this.person.person_id}/`,
        });
      } catch (error) {
        this.showErrorModal({
          content: "Не удалось удалить участника",
          message: error.message,
        });
        this.pending = false;
        this.$emit("close");
        return;
      }

      const persons = [...this.team.persons];
      const idx = findIndex(persons, (n) => {
        return n.person.user.id === this.person.id;
      });
      if (idx >= 0) {
        persons.splice(idx, 1);
        this.$store.commit("participant/SET_TEAM", {
          ...this.team,
          persons: persons,
          profile_id: this.profileId,
        });
      }

      this.pending = false;
      this.$emit("close");
    },
  },
};
</script>

<style></style>
