<template>
  <div></div>
</template>

<script>
export default {
  name: "FinalJuniorProfileStage",
};
</script>

<style></style>
