<template>
  <BaseLoadingBox :pending="pending">
    <!-- Если юзер прошел во второй этап по этому профилю  -->
    <template v-if="userProfile">
      <!-- результат командного этапа -->
      <StageStatusPanel
        class="mb-l"
        :profile-id="profileId" />

      <section class="mb-m">
        <h3 class="text-size-h3 text-bold mb-s">Набранные баллы</h3>
        <div
          v-if="profileScores.length"
          class="prof-scores">
          <ScoreInfo
            v-for="(item, idx) in profileScores"
            :key="idx"
            :score="item.score"
            :track="item.track"
            :updated="item.updated_at"
            class="mb-m" />
        </div>
        <div
          v-else
          class="color-meta">
          Нет данных по набранным баллам
        </div>
      </section>

      <section
        v-show="!teamInfoHidden"
        id="team"
        class="mb-l">
        <template v-if="team">
          <h3 class="text-size-h3 text-bold mb-s">
            Ваша команда &laquo;{{ team.title }}&raquo;
          </h3>
          <div
            v-if="teamStageTrack"
            class="mb-xs">
            Трек: {{ teamStageTrack.title }}
          </div>
          <TeamSize
            v-if="maxUsers"
            :max="maxUsers"
            class="mb-xs"
            :current="teamMembers.length + 1" />
          <div v-if="team.contact_link">
            Чат команды:
            <a
              :href="team.contact_link"
              target="_blank"
              rel="noopener noreferrer"
              class="link"
              >{{ team.contact_link }}</a
            >
          </div>
        </template>
        <template v-else>
          <h3 class="text-size-h3 text-bold mb-s">Команда</h3>
          <div v-if="teamStageTrack">Трек: {{ teamStageTrack.title }}</div>
        </template>
        <TeamBuildingStageText
          class="mb-m"
          :stage="userProfile.buildingStage" />
        <!-- Если этап командообразования открыт -->
        <div v-if="userProfile.allowTeamBuilding">
          <!-- если нет команды  -->
          <div
            v-if="!userProfile.team_id"
            class="row">
            <div class="col-md-9 col-xs-12">
              <p class="mb-m">
                Вам нужно создать команду или присоединиться
                к&nbsp;существующей. Без команды вы&nbsp;не&nbsp;сможете решать
                командные задания.
              </p>

              <BaseButton
                theme="primary-border"
                class="mb-xs"
                @click="openEditTeamModal(null)"
                >Создать команду</BaseButton
              >&emsp;
              <BaseButton
                v-if="!profile.team"
                theme="primary-border"
                @click="openFindTeamModal(profile.id)"
                >Найти команду</BaseButton
              >
            </div>
          </div>
        </div>
        <!-- если есть команда и мы ее получили  -->
        <div v-if="team">
          <div class="row">
            <div class="col-md-8 col-xs-12">
              <div
                v-if="team.description"
                class="mb-m">
                <div
                  v-if="isDescriptionShown"
                  class="mb-s">
                  <div class="text-bold mb-xs">Описание:</div>
                  <div class="typograpy">
                    {{ team.description }}
                  </div>
                </div>

                <a
                  href="#"
                  class="link link--pseudo"
                  @click.prevent="isDescriptionShown = !isDescriptionShown"
                  >{{
                    isDescriptionShown ? "Скрыть описание" : "Показать описание"
                  }}</a
                >
              </div>
              <template v-if="isTeamOwner">
                <div class="mb-m">
                  <BaseButton
                    v-if="userProfile.allowTeamBuilding"
                    theme="primary"
                    class="mr-m mb-s"
                    @click="openFindMemberModal"
                    >Пригласить участников</BaseButton
                  >
                  <BaseButton
                    theme="primary-border"
                    class="mr-m mb-s"
                    @click.prevent="openEditTeamModal(team.id)"
                    >Редактировать команду</BaseButton
                  >
                  <BaseButton
                    v-if="userProfile.allowTeamBuilding"
                    theme="primary-border"
                    @click.prevent="
                      openLeaveTeamModal(myTeamPerson && myTeamPerson.person_id)
                    "
                    >Покинуть команду</BaseButton
                  >
                </div>
                <div class="mb-m">
                  <div class="text-bold mb-xs">
                    Ссылка-приглашение в команду:
                  </div>
                  <CopyInput :value="inviteLink" />
                </div>
              </template>
            </div>
          </div>
          <div class="text-bold mb-xs">Состав команды:</div>
          <div class="row team-members">
            <div class="col-md-6 col-sm-6 col-xs-12 team-members__item">
              <TeamMemberCard
                v-if="teamOwner"
                :person="teamOwner"
                :is-captain="true"
                :is-me="teamOwner.id === user.talent_id">
                <template
                  v-if="userProfile.allowTeamBuilding"
                  #controls>
                  <div v-if="teamOwner.id === user.talent_id">
                    <a
                      href="#"
                      class="link"
                      disabled
                      @click.prevent="openLeaveTeamModal(null)"
                      >Покинуть команду</a
                    >
                  </div>
                </template>
                <template #remove>
                  <img
                    src="@/assets/images/teams/captain.svg"
                    alt="Иконка капитана"
                    title="Капитан команды" />
                </template>
              </TeamMemberCard>
            </div>
            <div
              v-for="item in teamMembers"
              :key="item.id"
              class="col-md-6 col-sm-6 col-xs-12 team-members__item">
              <TeamMemberCard
                :person="item"
                :is-captain="false"
                :is-me="item.id === user.talent_id">
                <template
                  v-if="userProfile.allowTeamBuilding"
                  #controls>
                  <template v-if="isTeamOwner">
                    <a
                      href="#"
                      class="link"
                      @click.prevent="openCaptainModal(item)"
                      >Назначить капитаном</a
                    >
                  </template>
                  <template v-else-if="item.id === user.talent_id">
                    <a
                      href="#"
                      class="link"
                      @click.prevent="openLeaveTeamModal(item.person_id)"
                      >Покинуть команду</a
                    >
                  </template>
                </template>
                <template
                  v-if="userProfile.allowTeamBuilding && isTeamOwner"
                  #remove>
                  <button
                    title="Исключить из команды"
                    class="remove-member"
                    @click.prevent="openRemoveMemberModal(item)">
                    <BaseIcon
                      valign="top"
                      glyph="close" />
                  </button>
                </template>
              </TeamMemberCard>
            </div>
          </div>
          <div
            v-if="!team.contact_link"
            class="mt-m">
            <h4 class="text-size-h4 text-bold">Сообщение команде</h4>
            <div>
              <div class="row">
                <div class="col-md-10 col-xs-12">
                  <p class="mb-m">
                    Раз в&nbsp;24&nbsp;часа вы&nbsp;можете отправить сообщение
                    всей команде, например, оставить ваши контакты, чтобы
                    сокомандники могли с&nbsp;вами связаться. Длина сообщения
                    ограничена&nbsp;&mdash; 140&nbsp;символов, поэтому подумайте
                    над формулировками.
                  </p>
                </div>
              </div>
              <ValidationObserver
                ref="msg_form"
                tag="form"
                @submit.prevent="handleMsg">
                <div class="mb-m">
                  <BaseLoadingBox :pending="msgPending">
                    <ValidationProvider
                      v-slot="{ errors }"
                      rules="required|max:140">
                      <BaseInput
                        v-model="msg"
                        :errors="errors"
                        type="textarea"
                        rows="4" />
                    </ValidationProvider>
                  </BaseLoadingBox>
                </div>
                <div
                  v-if="msgError"
                  class="mb-s form-error">
                  Ошибка: {{ msgError }}
                </div>
                <BaseButton
                  type="sumbit"
                  :disabled="msgPending"
                  theme="primary-border"
                  >Отправить сообщение</BaseButton
                >
              </ValidationObserver>
            </div>
          </div>
          <BaseButton
            v-else
            tag="a"
            :href="team.contact_link"
            class="mt-m"
            target="_balnk"
            rel="noopener noreferrer"
            >Перейти в чат команды</BaseButton
          >
        </div>
        <PortfolioPanel
          v-if="!isJunior"
          class="mt-m mb-m" />
      </section>

      <EducationBlocks
        v-if="educationBlocks.length"
        :blocks="educationBlocks"
        class="mb-l mt-l" />

      <section
        id="tasks"
        class="mb-l">
        <h3 class="text-size-h3 text-bold mb-s">Задания</h3>

        <div v-if="allowedActivities.length">
          <ActivityCard
            v-for="item in allowedActivities"
            :key="item.id"
            :activity="item"
            class="mb-xs"
            :score="
              activityScores ? activityScores[item.id] : null
            "></ActivityCard>
        </div>

        <div v-else>Расписание уточняется, задания появятся позже.</div>
      </section>
    </template>
  </BaseLoadingBox>
</template>

<script>
import { mapGetters } from "vuex";
import ActivityCard from "@/components/profile/ActivityCard";
import { teamInviteURL } from "@/utils/urls";
import TeamMemberCard from "@/components/user/TeamMemberCard";
import {
  TEAM_STAGE,
  MODAL_DYNAMIC_DEFAULTS,
  HIDE_TEAMS_PROPFILE,
} from "@/constants";
import CopyInput from "@/components/CopyInput";
import SetCaptainModal from "@/components/user/modals/SetCaptainModal";
import TeamEditModal from "@/components/user/modals/TeamEditModal";
import TeamFindModal from "@/components/user/modals/TeamFindModal";
import LeaveTeamModal from "@/components/user/modals/LeaveTeamModal";
import MemberFindModal from "@/components/user/modals/MemberFindModal";
import RemoveTeamMemberModal from "@/components/user/modals/RemoveTeamMemberModal";
import TeamSize from "@/components/TeamSize";
import TeamBuildingStageText from "@/components/user/TeamBuildingStageText";
import { request } from "@/services/api";
import ScoreInfo from "@/components/profile/ScoreInfo";
import StageStatusPanel from "@/components/user/StageStatusPanel";
import PortfolioPanel from "@/components/portfolio/PortfolioPanel";
import EducationBlocks from "@/components/education/EducationBlocks.vue";

export default {
  name: "TeamProfileStage",
  components: {
    ActivityCard,
    TeamMemberCard,
    CopyInput,
    TeamSize,
    TeamBuildingStageText,
    ScoreInfo,
    StageStatusPanel,
    PortfolioPanel,
    EducationBlocks,
  },
  props: {
    profileId: {
      type: Number,
      required: true,
    },
    activities: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      pending: true,
      isDescriptionShown: false,
      msg: "",
      msgPending: false,
      msgError: false,
    };
  },

  computed: {
    ...mapGetters({
      activityScores: "participant/activityScores",
      user: "user/user",
      finalStageList: "participant/finalStageList",
      isHighSchool: "participant/isHighSchool",
      isJunior: "participant/isJunior",
    }),
    profile() {
      return this.$store.state.profile.profiles[this.profileId];
    },
    userProfile() {
      return this.$store.getters["participant/teamStageList"].find((n) => {
        return n.profile_id === this.profileId;
      });
    },
    inviteLink() {
      const { team, isTeamOwner } = this;
      if (!isTeamOwner || !team?.invite_code) return;
      return teamInviteURL(team.invite_code, this.profileId, team.id);
    },
    team() {
      return this.$store.state.participant.teams[this.profileId];
    },
    /**
     * Члены команды без капитана
     */
    teamMembers() {
      const { team, user } = this;
      if (!team || !team?.persons) return [];
      return team.persons
        .filter(
          (tUser) => tUser?.person?.user && tUser.person.user.id !== team.owner
        )
        .map((n) => {
          return {
            person_id: n.id,
            ...n.person.user,
            isMe: n.person.user.id === user.talent_id,
          };
        });
    },
    // myTeamPerson() {
    //   return this.teamMembers.find((n) => n.isMe);
    // },
    teamOwner() {
      const { team } = this;
      if (!team) return;
      const owner = team.persons.find((teamPerson) => {
        return teamPerson?.person?.user?.id === team.owner;
      });
      return owner?.person?.user;
    },
    isTeamOwner() {
      const { team, user } = this;
      if (!team) return;
      return team.owner === user.talent_id;
    },
    currentStage() {
      const { profile } = this;
      return profile?.steps?.find((n) => n.stage === TEAM_STAGE);
    },
    eventId() {
      return this.currentStage?.talent_event_id;
    },
    maxUsers() {
      return this.$store.state.profile.events[this.eventId]?.max_users;
    },
    allowedActivities() {
      const { activities } = this;
      if (!activities) return [];
      return activities;
    },
    teamStageTrack() {
      const { profileId } = this;
      return this.$store.state.tracks[
        this.$store.getters["participant/teamProfileTracks"][profileId]
      ];
    },
    /**
     * Баллы за профильи командного этапа
     */
    profileScores() {
      const scores = this.$store.state.participant.scores?.profile_scores;
      if (!scores) return [];
      return scores
        .filter((n) => {
          return n.stage === TEAM_STAGE && n.profile_id === this.profileId;
        })
        .map((n) => {
          const result = { ...n };
          if (n.track_id) {
            result.track = this.$store.state.tracks[n.track_id]?.title;
          } else {
            result.track = this.$store.state.participant.track?.title;
          }
          return result;
        });
    },

    teamInfoHidden() {
      return HIDE_TEAMS_PROPFILE.includes(this.profileId);
    },
    educationBlocks() {
      const list = this.$store.state.participant.edublocks;
      if (!list?.length) return [];
      return list.filter((n) => {
        return n.profiles && n.profiles.some((id) => id === this.profileId);
      });
    },
  },
  watch: {
    profileId: {
      async handler(val) {
        if (val) {
          this.pending = true;
          try {
            await this.$store.dispatch("participant/getTeam", this.profileId);
          } catch (error) {
            this.showErrorModal({
              content: "Произошла ошибка при получении данных о команде",
              message: error.message,
              status: error.status,
              report: true,
            });
          }
          this.pending = false;
        }
      },
    },
  },
  async created() {
    this.pending = true;
    try {
      if (this.userProfile) {
        this.$store.dispatch("user/getTelegramAccounts");
        const requests = [
          this.$store.dispatch("participant/getTeam", this.profileId),
          this.$store.dispatch("participant/getScores"),
        ];

        if (this.eventId) {
          requests.push(
            this.$store.dispatch("profile/getEvents", [this.eventId])
          );
        }
        await Promise.all(requests);
      }
    } catch (error) {
      this.showErrorModal({
        content: "Произошла ошибка при получении данных",
        message: error.message,
        status: error.status,
        report: true,
      });
    }
    this.pending = false;
  },
  methods: {
    /**
     * Создать или редактировать команду,
     * в зависимости от наличия параметра team_id
     */
    openEditTeamModal(team_id) {
      /**
       * Если не указано id мероприятия
       * то покажем ошибку
       */
      if (!this.eventId) {
        this.showErrorModal({
          content:
            "У выбранного профиля не указано мероприятие командного этапа. Пожалуйста, сообщите нам об этой ошибке.",
          report: true,
        });
      }
      this.$modal.show(
        TeamEditModal,
        {
          profileId: this.profileId,
          eventId: this.eventId,
          teamId: team_id,
        },
        MODAL_DYNAMIC_DEFAULTS
      );
    },
    openCaptainModal(person) {
      this.$modal.show(
        SetCaptainModal,
        {
          teamId: this.team.id,
          person: person,
          profileId: this.profileId,
        },
        MODAL_DYNAMIC_DEFAULTS
      );
    },
    openFindTeamModal() {
      this.$modal.show(
        TeamFindModal,
        {
          profileId: this.profileId,
        },
        MODAL_DYNAMIC_DEFAULTS
      );
    },
    openLeaveTeamModal(personId) {
      this.$modal.show(
        LeaveTeamModal,
        {
          profileId: this.profileId,
          personId: personId,
        },
        MODAL_DYNAMIC_DEFAULTS
      );
    },
    openRemoveMemberModal(person) {
      this.$modal.show(
        RemoveTeamMemberModal,
        {
          profileId: this.profileId,
          person,
        },
        MODAL_DYNAMIC_DEFAULTS
      );
    },
    openFindMemberModal() {
      if (this.maxUsers && this.team?.persons?.length === this.maxUsers) {
        this.showErrorModal({
          title: "Ваша команда укомплектована",
          content:
            "В вашей команде не осталось свободных мест. Чтобы пригласить нового участника, вам нужно удалить кого-то из команды",
        });
        return;
      }

      this.$modal.show(
        MemberFindModal,
        {
          profileId: this.profileId,
        },
        MODAL_DYNAMIC_DEFAULTS
      );
    },
    /**
     * Отправить сообщение команде
     */
    async handleMsg() {
      const isValid = await this.$refs.msg_form.validate();
      if (this.msgPending) return;
      this.msgPending = true;
      this.msgError = "";
      if (!isValid) {
        this.msgPending = false;
        return;
      }
      try {
        await request({
          method: "POST",
          url: `/team/${this.team?.id}/notify`,
          data: {
            message: this.msg,
          },
        });
        window.dataLayer?.push({
          event: "ntoTeamEvent",
          eventCategory: "nto_team",
          eventAction: "send_team_msg",
        });
      } catch (error) {
        this.msgError = error.message;
      }
      this.msgPending = false;
    },
  },
};
</script>

<style lang="less" scoped>
.team-members {
  --grid-gutter-width: 10px;

  &__item {
    margin-bottom: 10px;
    margin-top: 25px;
  }

  .remove-member {
    background-color: transparent;
    padding: 0;
    border: 0;
    font-size: 24px;
    cursor: pointer;
    color: @link-color;
    opacity: 0.5;
    transition: opacity 0.3s;

    &:hover {
      opacity: 1;
    }
  }
}

.result {
  display: flex;
  flex-flow: row nowrap;

  &__icon {
    width: 32px;
    flex-grow: 0;
    flex-shrink: 0;
    margin-right: 10px;
    font-size: 28px;
  }

  &__data {
    align-self: center;
  }
}
</style>
