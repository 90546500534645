<template>
  <div>
    <div v-if="userProfile">
      <StageStatusPanel
        class="mb-l"
        :profile-id="profileId" />
      <section class="mb-m">
        <h3 class="text-size-h3 text-bold mb-s">Набранные баллы</h3>
        <div
          v-if="profileScores"
          class="prof-scores">
          <ScoreInfo
            v-for="(item, idx) in profileScores"
            :key="idx"
            :score="item.score"
            :track="item.track"
            :updated="item.updated_at"
            class="mb-m" />
        </div>
        <div
          v-else
          class="color-meta">
          Нет данных по набранным баллам
        </div>
      </section>
      <EducationBlocks
        v-if="educationBlocks.length"
        :blocks="educationBlocks"
        class="mb-l mt-l" />
      <section>
        <h3 class="text-size-h3 text-bold mb-s">Задания</h3>
        <div v-if="activities">
          <div
            v-if="isHybridTrack"
            class="mb-m">
            <div>
              Задания повышенной сложности
              {{ isHiddenHybridActivity ? "скрыты" : "доступны" }} для этого
              профиля.
              <a
                href="#"
                class="link"
                @click.prevent="openProfieTrackSelectModal"
                >Изменить сложность</a
              >
            </div>
          </div>
          <ActivityCard
            v-for="item in activities"
            :key="item.id"
            :activity="item"
            class="mb-xs"
            :score="
              activityScores ? activityScores[item.id] : null
            "></ActivityCard>
        </div>

        <div v-else>Расписание уточняется, задания появятся позже.</div>
      </section>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import ActivityCard from "@/components/profile/ActivityCard";
import { MODAL_DYNAMIC_DEFAULTS } from "@/constants";
import ScoreInfo from "@/components/profile/ScoreInfo";
import StageStatusPanel from "@/components/user/StageStatusPanel";
import ProfieTrackSelectModal from "@/components/user/modals/ProfieTrackSelectModal";
import EducationBlocks from "@/components/education/EducationBlocks.vue";

export default {
  name: "IndividualProfileStage",
  components: {
    ActivityCard,
    ScoreInfo,
    StageStatusPanel,
    EducationBlocks,
  },
  props: {
    profileId: {
      type: Number,
      required: true,
    },
    activities: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  computed: {
    ...mapState({
      scores: (state) => state.participant.scores,
      tracks: (state) => state.tracks,
      track: (state) => state.participant.track,
    }),
    ...mapGetters({
      activityScores: "participant/activityScores",
      isHybridTrack: "participant/isHybridTrack",
      hybridProfilesTracks: "participant/hybridProfilesTracks",
      individualProfilesScores: "participant/individualProfilesScores",
      isJunior: "participant/isJunior",
    }),
    educationBlocks() {
      const list = this.$store.state.participant.edublocks;
      if (!list?.length) return [];
      return list.filter((n) => {
        return n.profiles && n.profiles.some((id) => id === this.profileId);
      });
    },
    userProfile() {
      return this.$store.getters["participant/selectedList"].find((n) => {
        return n.profile_id === +this.profileId && !n.rejected;
      });
    },
    /**
     * Баллы за профиль индивидуального этапа
     */
    profileScores() {
      if (!this.individualProfilesScores) return {};
      return this.individualProfilesScores[this.profileId];
    },
    isHiddenHybridActivity() {
      return this.hybridProfilesTracks[this.profileId] === this.track?.id;
    },
  },
  methods: {
    openProfieTrackSelectModal() {
      this.$modal.show(
        ProfieTrackSelectModal,
        {
          profileId: this.profileId,
        },
        MODAL_DYNAMIC_DEFAULTS
      );
    },
  },
};
</script>

<style lang="less" scoped>
.result {
  display: flex;
  flex-flow: row nowrap;

  &__icon {
    width: 32px;
    flex-grow: 0;
    flex-shrink: 0;
    margin-right: 10px;
    font-size: 28px;
  }

  &__data {
    align-self: center;
  }
}
</style>
