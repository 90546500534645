<template>
  <div class="team-modal">
    <BaseModalContent
      :dynamic="true"
      @close="$emit('close')">
      <BaseLoadingBox :pending="pending">
        <h2 class="text-bold text-size-h2 mb-m">
          Передать управление командой
        </h2>
        <p class="mb-m">
          Вы собираетесь передать роль капитана команды &laquo;{{
            team && team.title
          }}&raquo; другому участнику. Обратите внимание, что только капитан
          может приглашать и&nbsp;удалять других участников.
        </p>
        <p class="mb-l">
          Назначить капитаном команды
          <span class="text-bold">&laquo;{{ team && team.title }}&raquo;</span>
          участника: <span class="text-bold">{{ personName }}</span> ({{
            person.email
          }})?
        </p>
        <div
          v-if="error"
          class="mt-m mb-m form-error">
          {{ error }}
        </div>
        <BaseButton
          theme="primary"
          class="mr-m mb-m"
          @click.prevent="handleSetCaptain"
          >Да, назначить</BaseButton
        >
        <BaseButton
          theme="primary-border"
          @click.prevent="$emit('close')"
          >Нет, я передумал(а)</BaseButton
        >
      </BaseLoadingBox>
    </BaseModalContent>
  </div>
</template>

<script>
import { talentRequest } from "@/services/api";

export default {
  name: "SetCaptainModal",
  props: {
    person: {
      type: Object,
      required: true,
    },
    profileId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      pending: false,
    };
  },
  computed: {
    personName() {
      const { person } = this;
      return [person.first_name, person.last_name].filter(Boolean).join(" ");
    },
    team() {
      return this.$store.state.participant.teams[this.profileId];
    },
    user() {
      return this.$store.getters["user/user"];
    },
  },
  methods: {
    async handleSetCaptain() {
      const newOwnreId = this.person.id;
      this.pending = true;
      try {
        await talentRequest({
          method: "PATCH",
          url: `/api/users/${this.user.talent_id}/teams/${this.team?.id}/`,
          data: {
            owner: newOwnreId,
          },
        });
        this.$store.commit("participant/SET_TEAM", {
          ...this.team,
          owner: newOwnreId,
          profile_id: this.profileId,
        });
      } catch (error) {
        this.showErrorModal({
          title: "Не передать управление командой",
          message: error.message,
        });
      }
      this.pending = false;
      this.$emit("close");
    },
  },
};
</script>

<style></style>
