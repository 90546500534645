<template>
  <div
    v-show="!isClosed"
    class="portfolio-panel panel radius-xl">
    <button
      v-if="closable"
      title="Скрыть"
      class="close"
      @click.prevent="handleClose">
      <BaseIcon glyph="close" />
    </button>
    <div class="card">
      <div class="img">
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24">
          <path
            d="M22.766 3.037h-4.279v-0.594c0-0.356-0.288-0.643-0.643-0.643h-11.688c-0.355 0-0.643 0.288-0.643 0.643v0.594h-4.28c-0.355 0-0.643 0.287-0.643 0.643v2.909c0 0.796 0.312 1.543 0.875 2.107s1.31 0.875 2.106 0.875h2.106c0.619 2.676 2.89 4.727 5.681 5.002v2.896h-1.721c-0.991 0-1.806 0.808-1.806 1.806v2.283c0 0.355 0.287 0.643 0.643 0.643h7.054c0.356 0 0.643-0.288 0.643-0.643v-2.283c0-0.992-0.808-1.806-1.806-1.806h-1.72v-2.896c2.786-0.275 5.063-2.327 5.681-5.002h2.1c1.641 0 2.982-1.335 2.982-2.982v-2.908c0-0.355-0.288-0.643-0.643-0.643zM14.88 19.268v1.635h-5.768v-1.641c0-0.288 0.232-0.521 0.521-0.521h4.733c0.281 0.007 0.515 0.239 0.515 0.527zM5.513 8.284h-1.941c-0.453 0-0.875-0.177-1.199-0.497-0.318-0.324-0.496-0.747-0.496-1.2v-2.265h3.637v3.961zM17.201 8.113c0 2.866-2.332 5.204-5.204 5.204s-5.198-2.332-5.198-5.204v-5.027h10.402v5.027zM18.486 4.323h3.637v2.265c0 0.936-0.759 1.696-1.696 1.696h-1.947v-3.962h0.007z"></path>
        </svg>
      </div>

      <div class="data">
        <div v-if="!isPublic">
          <div class="text-bold">Ссылка на ваше портфолио:</div>
          <div class="link-block">
            <div class="link-block__inner">
              <a
                :href="portfoliUrl"
                target="_blank"
                rel="noopener noreferrer"
                class="link-block__link"
                >{{ portfoliUrl }}</a
              >
              <button
                class="link-block__btn"
                title="Копировать ссылку"
                @click.prevent="handleCopy">
                <BaseIcon glyph="copy" />
              </button>
              <button
                class="link-block__btn"
                title="Узнать больше о публичном портфолио"
                @click.prevent="openInfo">
                <BaseIcon glyph="info" />
              </button>
            </div>
          </div>
        </div>
        <div v-else>
          <div>
            Публичное портфолио на&nbsp;платформе &laquo;Талант&raquo; поможет
            вам рассказать о&nbsp;себе и&nbsp;своих достижениях потенциальным
            сокомандникам.
          </div>
          <span
            class="link link--pseudo"
            @click.prevent="openInfo"
            >Узнать больше</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { copyToClipboard } from "@/utils";
import PortfolioModal from "./PortfolioModal";
import { MODAL_DYNAMIC_DEFAULTS } from "@/constants";
export default {
  name: "PortfolioPanel",
  props: {
    closable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isClosed: false,
    };
  },
  computed: {
    user() {
      return this.$store.getters["user/user"];
    },
    isPublic() {
      return true;
    },
    portfoliUrl() {
      if (!this.user?.talent_id) return;
      return `${process.env.VUE_APP_TALENT_BASE_URL}/user/${this.user?.talent_id}`;
    },
  },
  created() {
    if (this.closable) {
      this.isClosed = !!localStorage?.getItem("portfolio-closed");
    }
  },
  methods: {
    openInfo() {
      this.$modal.show(PortfolioModal, {}, MODAL_DYNAMIC_DEFAULTS);
    },
    handleClose() {
      this.isClosed = true;
      localStorage?.setItem("portfolio-closed", "1");
    },

    async handleCopy() {
      const { portfoliUrl } = this;
      if (!portfoliUrl) return;
      copyToClipboard(portfoliUrl);
      this.$notify({
        title: portfoliUrl,
        text: "Ссылка на ваше портфолио, скопирована",
        type: "success",
        group: "base",
        duration: 10000,
      });
    },
  },
};
</script>

<style lang="less" scoped>
.portfolio-panel {
  // background: @dodger-blue;
  position: relative;

  .card {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .img {
    flex-grow: 0;
    flex-shrink: 0;
    width: 60px;
    height: 60px;
    background-color: @light-gray;
    border-radius: 50%;
    margin-right: 20px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    fill: @primary-color;

    svg {
      width: 32px;
      height: 32px;
    }
  }

  .close {
    .btn-reset();
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: @light-gray;
    width: 32px;
    height: 32px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    border-radius: 50%;

    &:hover {
      color: @primary-color;
    }
  }
}

.link-block {
  &__inner {
    display: inline-flex;
    max-width: 100%;
    align-items: center;
    justify-content: flex-start;
  }

  &__link {
    flex-grow: 1;
    flex-shrink: 1;
    min-width: 20px;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-right: 10px;
    font-size: 16px;
    // font-weight: bold;
    text-decoration: none;
    color: @link-color;
    line-height: 1;
    overflow: hidden;
  }

  &__btn {
    .btn-reset();
    width: 28px;
    height: 28px;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #c0c0c0;
    transition: color 0.3s;

    &:hover {
      color: @primary-color;
    }
  }
}
</style>
