<template>
  <div
    class="tm-card"
    :class="{ 'tm-card--captain': isCaptain }">
    <BaseAvatar
      :image="person.avatar"
      :gender="person.sex"
      class="tm-card__avatar" />
    <div class="tm-card__data">
      <div class="text-bold">
        {{ personName }}
      </div>
      <div class="text-size-s">
        <a
          v-if="isValidEmail"
          class="link link--invert"
          :href="`mailto:${person.email}`"
          >{{ person.email }}</a
        >
        <span v-else>{{ person.email }}</span>
      </div>
      <div
        v-if="person.address"
        class="text-size-s">
        {{ person.address }}
      </div>
      <div class="tm-card__remove">
        <slot name="remove"></slot>
      </div>
      <div class="text-size-s mt-s">
        <slot name="controls"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TeamMemberCard",
  props: {
    person: {
      type: Object,
      required: true,
    },
    isCaptain: {
      type: Boolean,
      default: false,
    },
    // isMe: {
    //   type: Boolean,
    //   default: false,
    // },
  },
  computed: {
    personName() {
      const { person } = this;
      if (!person) return;
      return [person.first_name, person.last_name].filter(Boolean).join(" ");
    },
    /**
     * Проверяет, был ли email маскирован
     */
    isValidEmail() {
      const { person } = this;
      if (!person?.email) return false;
      return person.email.split("@")[0] !== "***";
    },
  },
};
</script>

<style lang="less" scoped>
.tm-card {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: #fff;
  padding: 12px 18px;
  border-radius: @radius-m;
  box-shadow: @shadow-default;
  position: relative;
  min-height: 100%;

  &__remove {
    position: absolute;
    top: 12px;
    right: 18px;
  }

  &__avatar {
    flex-grow: 0;
    flex-shrink: 0;
    width: 60px;
    margin-right: 1rem;
    border: 4px solid #fff;
    box-shadow: 0px 10px 10px fade(@tropical-blue, 50%);
    transform: translateY(-50%);
  }

  &__data {
    min-width: 0;
    flex-grow: 1;
  }

  .ext-icon {
    margin-top: -0.2em;
  }
}
</style>
