<template>
  <div
    class="team-size"
    :title="`Свобоных мест в команде: ${max - current}`">
    <ul
      class="team-size__list"
      :class="listClass">
      <li
        v-for="item in list"
        :key="item.id"
        :class="{ 'is-active': item.active }">
        <BaseIcon
          glyph="person"
          valign="middle" />
      </li>
    </ul>
    <div>{{ current }}/{{ max }}</div>
  </div>
</template>

<script>
const MAX_USERS = 10;
export default {
  name: "TeamSize",
  props: {
    max: {
      type: Number,
      default: MAX_USERS,
    },
    current: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    listClass() {
      const { max } = this;
      if (max <= 5) {
        return "normal";
      }
      return "compact";
    },
    list() {
      const max = Math.min(this.max, MAX_USERS);
      let list = [];
      for (let i = 1; i <= max; i++) {
        list.push({
          id: i,
          active: i <= this.current,
        });
      }
      // Если лимит конячий, то добавим еще пару пустых
      if (this.max > MAX_USERS) {
        list = [
          ...list,
          {
            id: max + 1,
            active: false,
          },
          {
            id: max + 2,
            active: false,
          },
        ];
      }
      return list;
    },
  },
};
</script>

<style lang="less" scoped>
.team-size {
  display: flex;
  flex-flow: row nowrap;
  &__list {
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
    margin-right: 0.4em;
    flex-flow: row nowrap;
    color: lighten(@primary-color, 40%);

    li:nth-child(1) {
      margin-left: 0 !important;
    }

    li {
      margin-left: -0.3em;

      &.is-active {
        color: @success-color !important;
      }
    }

    &.compact li {
      margin-left: -0.45em;
    }
  }
}
</style>
